import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Chip,
  Collapse,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { green } from "@mui/material/colors";
import * as React from "react";
import { UtilizationMetrics } from "../../@types/sitefactory-types";
import moderationStates from "./moderationStates";

export const NodeTable = (metricsData: UtilizationMetrics): JSX.Element => {
  // Node utilization table is closed/collapsed by default.
  const [open, setOpen] = React.useState(false);
  // Unique node types in metrics.
  const nodeTypes: string[] = [];
  const header: string[] = ["Paragraph"];
  let rows: string[][] = [[]];
  const rowsMap = new Map<string, Map<string, number>>();
  let moderationMap;

  // Create map from all the metrics.
  // Also fills out moderationStates and nodeTypes with unique values, to
  // create knowledge of how many of each exist, and to enable iterating
  // through them.
  metricsData.nodeMetrics.forEach((metric) => {
    const nodeType: string = metric.labels?.node_type ?? "";
    const moderationState: string = metric.labels?.moderation_state ?? "";
    if (!nodeTypes.includes(nodeType)) {
      nodeTypes.push(nodeType);
    }
    if (!moderationStates.includes(moderationState)) {
      moderationStates.push(moderationState);
    }
    moderationMap = rowsMap.get(nodeType) ?? new Map<string, number>();
    moderationMap.set(moderationState, metric.value);
    rowsMap.set(nodeType, moderationMap);
  });
  rows.push(nodeTypes);

  // Iterate through nodeTypes and moderationStates to create full table.
  moderationStates.forEach((moderationState) => {
    header.push(moderationState);
    const valuesArray: string[] = [];
    nodeTypes.forEach((nodeType) => {
      valuesArray.push(
        rowsMap.get(nodeType)?.get(moderationState)?.toString() ?? "N/A"
      );
    });
    rows.push(valuesArray);
  });

  // Transpose table.
  if (rows.length > 1) {
    rows.shift();
  }
  rows = rows[0].map((_, colIndex) => rows.map((row) => row[colIndex]));
  // Sort by paragraph name.
  rows.sort((a: string[], b: string[]) => {
    return a[0].localeCompare(b[0]);
  });

  let unusedNodeCount = 0;
  let unusedModeration: number;
  // Count unused node types.
  rows.forEach((nodeType) => {
    unusedModeration = 0;
    nodeType.forEach((stateCount) => {
      if (stateCount === "0") {
        unusedModeration++;
      }
    });
    unusedModeration === moderationStates.length && unusedNodeCount++;
  });

  return (
    <Container sx={{ minWidth: "100%", padding: 0 }} disableGutters>
      <Paper>
        <TableContainer>
          <Table>
            <TableRow sx={{ borderBottom: "unset" }}>
              <TableCell sx={{ width: 35, maxWidth: 35, padding: 1 }}>
                <IconButton size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell sx={{ width: 350, maxWidth: 350 }}>
                {"Expand/collapse node data"}
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>
                <Chip label={nodeTypes.length - unusedNodeCount + " in use."} />
                <Chip
                  label={unusedNodeCount + " unused."}
                  sx={{ marginLeft: 2 }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }} colSpan={3}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <Table size="small">
                      <TableHead>
                        {header.map((module) => {
                          return (
                            <TableCell
                              key={module}
                              sx={{
                                border: "1px solid lightgray",
                                position: "sticky",
                                top: 0,
                                minWidth: 130,
                                "&:last-child": {
                                  borderTopRightRadius: "4px",
                                },
                                fontWeight: "bold",
                              }}
                            >
                              {module}
                            </TableCell>
                          );
                        })}
                      </TableHead>
                      <TableBody>
                        {rows.map((row, key) => (
                          <TableRow key={key}>
                            {row.map((col, key2) => {
                              return (
                                <TableCell
                                  key={key2}
                                  sx={{
                                    bgcolor: getBackgroundColor(col),
                                    border: "1px solid lightgray",
                                  }}
                                >
                                  {col}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

function getBackgroundColor(count: string | ""): string | null {
  if (/^[1-9]/.test(count)) {
    // Green backround if exists.
    return green[100];
  }
  return null;
}
