import { ParagraphTable } from "../components/statistics/StatisticsParagraphTable";
import { useGetOne, useRecordContext } from "react-admin";
import { Site, UtilizationMetrics } from "../@types/sitefactory-types";
import { NodeTable } from "../components/statistics/StatisticsNodeTable";

export const SiteStatisticsShow = (): JSX.Element => {
  const site = useRecordContext<Site>();
  let statusReports;
  let paragraphReports: JSX.Element;
  let nodeReports: JSX.Element;

  // Fetch and render metrics.
  const {
    data: metricsData,
    isLoading: metricsIsLoading,
    error: metricsError,
  } = useGetOne<UtilizationMetrics>("siteStatistics", {
    id: site.id,
  });
  if (metricsIsLoading) {
    statusReports = (
      <span>
        <h3>Metrics</h3>
        <p>Loading metrics for {site.id}...</p>
      </span>
    );
  } else if (
    metricsError ||
    metricsData === undefined ||
    metricsData.paragraphMetrics === undefined ||
    metricsData.nodeMetrics === undefined
  ) {
    statusReports = (
      <span>
        <h3>Metrics</h3>
        <p>Error when loading metrics for {site.id}</p>
      </span>
    );
  } else {
    if (metricsData.nodeMetrics.length < 1) {
      nodeReports = (
        <p>
          No values for {site.id}, kk_statistic module is likely not installed.
        </p>
      );
    } else {
      nodeReports = <NodeTable {...metricsData} />;
    }
    if (metricsData.nodeMetrics.length < 1) {
      paragraphReports = (
        <p>
          No values for {site.id}, kk_statistic module is likely not installed.
        </p>
      );
    } else {
      paragraphReports = <ParagraphTable {...metricsData} />;
    }
    statusReports = (
      <span>
        <h3>Node utilization</h3>
        {nodeReports}
        <h3>Paragraph utilization</h3>
        {paragraphReports}
      </span>
    );
  }
  return statusReports;
};
