// Based on simpleRestProvider with overwrites from @fusionworks/ra-data-nest-crud.
//
// This was mainly made to better be able to trace the interactions between
// react-admin and our CRUD api.

import { CondOperator, RequestQueryBuilder } from "@nestjsx/crud-request";
import { DataProvider, fetchUtils } from "ra-core";
import simpleRestProvider from "ra-data-simple-rest";

const composeFilter = (paramsFilter: any) => {
  if (
    paramsFilter === "" ||
    (typeof paramsFilter.q !== "undefined" && paramsFilter.q === "")
  ) {
    paramsFilter = {};
  }

  const flatFilter = fetchUtils.flattenObject(paramsFilter);
  return Object.keys(flatFilter).map((key) => {
    const splitKey = key.split("||");
    const ops = splitKey[1] ? splitKey[1] : CondOperator.CONTAINS;
    let field = splitKey[0];

    if (field.indexOf("_") === 0 && field.indexOf(".") > -1) {
      field = field.split(/\.(.+)/)[1];
    }
    return { field, operator: ops, value: flatFilter[key] };
  });
};

const nestCrudDataProvider = (
  apiUrl: string,
  httpClient = fetchUtils.fetchJson
): DataProvider => {
  // Use simple rest provider as base.
  const dataProvider = simpleRestProvider(apiUrl, httpClient);

  dataProvider.getList = (resource: string, params: any) => {
    const { page, perPage } = params.pagination;

    const queryParam: any = {
      filter: composeFilter(params.filter),
    };
    const query = RequestQueryBuilder.create(queryParam)
      .setLimit(perPage)
      .setPage(page)
      .sortBy(params.sort)
      .setOffset((page - 1) * perPage)
      .query();

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url, {}).then((response: any) => ({
      data: response.json.data,
      total: response.json.total,
    }));
  };

  dataProvider.getMany = (resource: string, params: any) => {
    const query = RequestQueryBuilder.create()
      .setFilter({
        field: "id",
        operator: CondOperator.IN,
        value: `${params.ids}`,
      })
      .query();

    const url = `${apiUrl}/${resource}?${query}`;
    return httpClient(url, {}).then((response: any) => ({
      data: response.json.data,
    }));
  };

  dataProvider.getManyReference = (resource: string, params: any) => {
    const { page, perPage } = params.pagination;
    const filter = composeFilter(params.filter);

    filter.push({
      field: params.target,
      operator: CondOperator.EQUALS,
      value: params.id,
    });

    const queryFilter: any = { filter };
    const query = RequestQueryBuilder.create(queryFilter)
      .sortBy(params.sort)
      .setLimit(perPage)
      .setPage(page)
      .query();

    const url = `${apiUrl}/${resource}?${query}`;
    return httpClient(url, {}).then((response: any) => ({
      data: response.json.data,
      total: response.json.total,
    }));
  };

  dataProvider.update = (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PATCH",
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));

  dataProvider.delete = (resource, params) => {
    const result: any = { data: { id: params.id } };
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    }).then(() => result);
  };

  // Add endpoints that does not adhere to the format nest-crud expects.
  // These functions are used by components that interacts directly with the
  // data-provider.
  dataProvider.getSiteStatusReport = (siteId: string, limit = 5) => {
    return httpClient(`${apiUrl}/siteStatusReport/${siteId}?limit=${limit}`, {
      method: "GET",
    }).then((response) => {
      return { data: response.json };
    });
  };
  return dataProvider;
};

export default nestCrudDataProvider;
