import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SquareIcon from "@mui/icons-material/Square";
import {
  Box,
  Collapse,
  Container,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
} from "@mui/material";
import { amber, green, red } from "@mui/material/colors";
import * as React from "react";
import { UtilizationMetrics } from "../../@types/sitefactory-types";
import moderationStates from "./moderationStates";

interface EnhancedTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  head: string[];
}

// Each value has a string to display in table and a (hidden) total count used
// for sorting.
interface TableDisplayValue {
  display: string;
  totalCount: number;
}

// Typeguard.
function isTableDisplayValue(
  data: TableDisplayValue | string
): data is TableDisplayValue {
  return (data as TableDisplayValue).totalCount !== undefined;
}

type Order = "asc" | "desc";

// Table header with ordering buttons.
function EnhancedTableHead(props: EnhancedTableHeadProps) {
  const { order, orderBy, onRequestSort, head } = props;

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 3,
      }}
    >
      <TableRow>
        {head.map((module) => {
          return (
            <TableCell
              key={module}
              sx={{
                bgcolor: "white",
                border: "1px solid lightgray",
                position: "sticky",
                top: 0,
                minWidth: 130,
                "&:last-child": {
                  borderTopRightRadius: "4px",
                },
                fontWeight: "bold",
              }}
            >
              <TableSortLabel
                active={orderBy === module}
                direction={orderBy === module ? order : "asc"}
                onClick={createSortHandler(module)}
                hideSortIcon={false}
              >
                {module}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export const ParagraphTable = (
  metricsData: UtilizationMetrics
): JSX.Element => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("Paragraph");
  // Paragraph utilization table is open/expanded by default.
  const [open, setOpen] = React.useState(true);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    let setDesc: boolean;
    if (property === "Paragraph") {
      // Paragraph default sorts as ascending.
      setDesc = orderBy === property && order === "asc";
    } else {
      // Utilization counts default sorts as descending.
      setDesc = !(orderBy === property && order === "desc");
    }
    setOrder(setDesc ? "desc" : "asc");
    setOrderBy(property);
  };
  // Unique paragraph types in metrics.
  const paragraphTypes: string[] = [];
  // Unique node types in metrics.
  const nodeTypes: string[] = [];
  const header: string[] = ["Paragraph"];
  let rows: (TableDisplayValue | string)[][] = [[]];
  const rowsMap = new Map<string, Map<string, Map<string, number>>>();
  let paragraphMap;
  let moderationMap;

  // Create map from all the metrics.
  // Also fills out moderationStates, paragraphTypes, and nodeTypes with unique
  // values, to create knowledge of how many of each exist, and to enable
  // iterating through them.
  metricsData.paragraphMetrics.forEach((metric) => {
    const nodeType: string = metric.labels?.node_type ?? "";
    const paragraphType: string = metric.labels?.paragraph_type ?? "";
    const moderationState: string = metric.labels?.moderation_state ?? "";
    if (!nodeTypes.includes(nodeType)) {
      nodeTypes.push(nodeType);
    }
    if (!paragraphTypes.includes(paragraphType)) {
      paragraphTypes.push(paragraphType);
    }
    if (!moderationStates.includes(moderationState)) {
      moderationStates.push(moderationState);
    }
    paragraphMap =
      rowsMap.get(nodeType) ?? new Map<string, Map<string, number>>();
    moderationMap =
      paragraphMap.get(paragraphType) ?? new Map<string, number>();
    moderationMap.set(moderationState, metric.value);
    paragraphMap.set(paragraphType, moderationMap);
    rowsMap.set(nodeType, paragraphMap);
  });
  rows.push(paragraphTypes);

  // Iterate through nodeTypes and paragraphTypes to create full table.
  nodeTypes.forEach((nodeType) => {
    header.push(nodeType);
    const valuesArray: TableDisplayValue[] = [];
    paragraphTypes.forEach((paragraphType) => {
      if (rowsMap.get(nodeType)?.get(paragraphType) === undefined) {
        valuesArray.push({ display: "N/A", totalCount: -1 });
      } else {
        // Display works for the 4 moderation states used in kk_moderation.
        const arrayValue: TableDisplayValue = {
          display:
            (rowsMap.get(nodeType)?.get(paragraphType)?.get("published") || 0) +
            " / " +
            (rowsMap.get(nodeType)?.get(paragraphType)?.get("draft") || 0) +
            " / " +
            (rowsMap.get(nodeType)?.get(paragraphType)?.get("archive") || 0) +
            " / " +
            (rowsMap.get(nodeType)?.get(paragraphType)?.get("trashed") || 0),
          totalCount: 0,
        };
        moderationStates.forEach((state: string) => {
          const value: number =
            rowsMap.get(nodeType)?.get(paragraphType)?.get(state) ?? 0;
          arrayValue.totalCount += value;
        });
        valuesArray.push(arrayValue);
      }
    });
    rows.push(valuesArray);
  });

  // Transpose table.
  if (rows.length > 1) {
    rows.shift();
  }
  rows = rows[0].map((_, colIndex) => rows.map((row) => row[colIndex]));
  // Sorting according to chosen column.
  const orderColumnIndex = header.indexOf(orderBy);
  if (order === "asc") {
    rows.sort(
      (
        a: (string | TableDisplayValue)[],
        b: (string | TableDisplayValue)[]
      ) => {
        // Create constants to make use of typeguard possible.
        const compareValueA = a[orderColumnIndex];
        const compareValueB = b[orderColumnIndex];
        if (
          isTableDisplayValue(compareValueA) &&
          isTableDisplayValue(compareValueB)
        ) {
          // Numerical sorting using the hidden totalCount value.
          return compareValueA.totalCount - compareValueB.totalCount;
        } else if (
          !isTableDisplayValue(compareValueA) &&
          !isTableDisplayValue(compareValueB)
        ) {
          // String compare sorting for Paragraph column.
          return compareValueA.localeCompare(compareValueB);
        }
        return 0;
      }
    );
  } else {
    rows.sort(
      (
        a: (string | TableDisplayValue)[],
        b: (string | TableDisplayValue)[]
      ) => {
        // Create constants to make use of typeguard possible.
        const compareValueA = a[orderColumnIndex];
        const compareValueB = b[orderColumnIndex];
        if (
          isTableDisplayValue(compareValueA) &&
          isTableDisplayValue(compareValueB)
        ) {
          // Numerical sorting using the hidden totalCount value.
          return compareValueB.totalCount - compareValueA.totalCount;
        } else if (
          !isTableDisplayValue(compareValueA) &&
          !isTableDisplayValue(compareValueB)
        ) {
          // String compare sorting for Paragraph column.
          return compareValueB.localeCompare(compareValueA);
        }
        return 0;
      }
    );
  }
  return (
    <Container sx={{ minWidth: "100%", padding: 0 }} disableGutters>
      <Paper>
        <TableContainer sx={{ overflowX: "initial", mb: "8px" }}>
          <Table>
            <TableRow sx={{ borderBottom: "unset" }}>
              <TableCell sx={{ width: 35, maxWidth: 35, padding: 1 }}>
                <IconButton size="small" onClick={() => setOpen(!open)}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
              <TableCell>{"Expand/collapse paragraph data"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }} colSpan={2}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box sx={{ margin: 1 }}>
                    <p>
                      <b>Table data: </b>Numbers indicate how many of the
                      paragraph type exist in a given node type by the
                      respective moderations states as (published / draft /
                      unpublished / trash).
                    </p>
                    <p>
                      <b>N/A</b> indicates that the given paragraph cannot be
                      used on the given nodetype.
                    </p>

                    <p>
                      <SquareIcon
                        sx={{ color: green[100], verticalAlign: "bottom" }}
                      />
                      Paragraph is published on node
                      <SquareIcon
                        sx={{
                          color: amber[100],
                          verticalAlign: "bottom",
                          marginLeft: "20px",
                        }}
                      />
                      Paragraph exists, but not in published node
                      <SquareIcon
                        sx={{
                          color: red[100],
                          verticalAlign: "bottom",
                          marginLeft: "20px",
                        }}
                      />
                      Paragraph is not used on node
                    </p>
                    <Table size="small">
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        head={header}
                      />
                      <TableBody>
                        {rows.map((row, key) => (
                          <TableRow key={key}>
                            {row.map((col, key2) => {
                              let displayValue: string;
                              if (isTableDisplayValue(col)) {
                                displayValue = col.display;
                              } else {
                                displayValue = col;
                              }
                              return (
                                <TableCell
                                  key={key2}
                                  sx={{
                                    bgcolor: getBackgroundColor(displayValue),
                                    border: "1px solid lightgray",
                                  }}
                                >
                                  {displayValue}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

function getBackgroundColor(count: string | ""): string | null {
  if (/^[1-9]/.test(count)) {
    // Green background for published paragraphs.
    return green[100];
  } else if (/[1-9]/.test(count)) {
    // Yellow background for existing, but not published paragraphs.
    return amber[100];
  } else if (/0/.test(count)) {
    // Red background for unused paragraphs.
    return red[100];
  }
  return null;
}
