import { Toolbar } from "@mui/material";
import { ParagraphTable } from "../components/statistics/StatisticsParagraphTable";
import { SetStateAction, useState } from "react";
import { SelectInput, useGetList, useGetOne } from "react-admin";
import { useForm, FormProvider } from "react-hook-form";
import { UtilizationMetrics } from "../@types/sitefactory-types";
import { NodeTable } from "../components/statistics/StatisticsNodeTable";

interface filterProps {
  setProfileFilter: React.Dispatch<SetStateAction<string>>;
  choices: any[] | undefined;
}

// Toolbar with dropdown filters.
function DropdownTableToolbar(props: filterProps) {
  const methods = useForm();
  return (
    <Toolbar disableGutters>
      <FormProvider {...methods}>
        <SelectInput
          sx={{ minWidth: 250, mr: "16px" }}
          source="profile"
          defaultValue={"all-profiles"}
          choices={props.choices}
          emptyText={"All profiles"}
          emptyValue={"all-profiles"}
          onChange={(event) => props.setProfileFilter(event.target.value)}
          helperText={false}
        />
      </FormProvider>
    </Toolbar>
  );
}

export const StatisticsShow = (): JSX.Element => {
  const [profileFilter, setProfileFilter] = useState("all-profiles");

  const profiles: any[] | undefined = [];
  let paragraphReports: JSX.Element;
  let nodeReports: JSX.Element;

  // Fetch and render profile list.
  const {
    data: profilesData,
    isLoading: profilesIsLoading,
    error: profilesError,
  } = useGetList("profileList");
  if (!profilesIsLoading && !profilesError && profilesData !== undefined) {
    profilesData.forEach((profile) => {
      profiles.push({ id: profile.id, name: profile.id });
    });
  }
  const dropdownHeader = (
    <DropdownTableToolbar
      setProfileFilter={setProfileFilter}
      choices={profiles}
    />
  );

  // Fetch and render metrics.
  const {
    data: metricsData,
    isLoading: metricsIsLoading,
    error: metricsError,
  } = useGetOne<UtilizationMetrics>("profileStatistics", {
    id: profileFilter,
  });

  if (metricsIsLoading) {
    paragraphReports = <p>Loading statistics for {profileFilter}...</p>;
    nodeReports = <p>Loading statistics for {profileFilter}...</p>;
  } else if (
    metricsError ||
    metricsData === undefined ||
    metricsData.paragraphMetrics === undefined ||
    metricsData.nodeMetrics === undefined
  ) {
    paragraphReports = <p>Error when loading statistics for {profileFilter}</p>;
    nodeReports = <p>Error when loading statistics for {profileFilter}</p>;
  } else {
    if (metricsData.nodeMetrics.length < 1) {
      nodeReports = (
        <p>
          No values for {profileFilter}, kk_statistic module is likely not
          installed.
        </p>
      );
    } else {
      nodeReports = <NodeTable {...metricsData} />;
    }
    if (metricsData.nodeMetrics.length < 1) {
      paragraphReports = (
        <p>
          No values for {profileFilter}, kk_statistic module is likely not
          installed.
        </p>
      );
    } else {
      paragraphReports = <ParagraphTable {...metricsData} />;
    }
  }
  const statusReports = (
    <span>
      {dropdownHeader}
      <h3>Node utilization</h3>
      {nodeReports}
      <h3>Paragraph utilization</h3>
      {paragraphReports}
    </span>
  );
  return statusReports;
};
